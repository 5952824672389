@import 'variable';

body{
    .user-progress-section{
        .user-progress-container{
            padding: 0;
            .wrapper{
                padding: 16px 16px 24px;
                margin-bottom: 8px;
                border-bottom: 1px solid $color_FFE5E9;
            }
            .progress-overview-blk{
                .activityLabel{
                    margin-bottom: 16px;
                }
                .all-time-summary{
                    .label{
                        margin-bottom: 8px;
                        text-transform: uppercase;
                    }
                    .summary-wrapper{
                        border: 1px solid $color_light_gray;
                        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                        border-radius: 8px;
                        padding: 16px;
                        .summary-item{
                            h1{
                                margin-bottom: 4px;
                                color: $color_black;
                                span{
                                    font-size: $font14;
                                    line-height: $font14;
                                }
                            }
                            p{
                                color: $color_gray;
                            }
                        }
                    }                    
                }
            }
            .label{
                margin-bottom: 16px;
            }
            .calorie-graph-blk{
                .calorie-graph{
                    position: relative;
                    .label{
                        a{
                            float: right;
                            position: relative;
                            top: 7px;
                            color: $color_primary;
                            span, img{
                                vertical-align: middle;
                            }
                            img{
                                margin-left: 5px;
                            }
                        }
                    }
                    .graph-indicators{
                        position: absolute;
                        top: 0;
                        right: 12px;
                        p{
                            position: relative;
                            margin-bottom: 8px;
                            padding-left: 20px;
                            &::before{
                                left: 0;
                                position: absolute;
                                content: "";
                                width: 16px;
                                height: 16px;
                                background: $color_success;
                            }
                            &.user{
                                &::before{
                                    background: $color_blue;
                                }
                            }
                        }
                    }
                    .graph-wrapper{
                        margin-bottom: 16px;
                    }
                    .alert{
                        margin-bottom: 8px;
                        p{
                            color: $color_gray;
                        }
                    }
                }
            }
            .weight-loss-blk{
                // border-bottom: none;
                .weight-loss{
                    .track-weight-loss-wrapper{
                        .alert{
                            color: $color_gray;
                            margin-bottom: 16px;
                            font-weight: $mediumFont;
                        }
                    }
                    .weight-loss-graph{
                        .label{
                            a{
                                float: right;
                                position: relative;
                                top: 7px;
                                color: $color_primary;
                                span, img{
                                    vertical-align: middle;
                                }
                                img{
                                    margin-left: 5px;
                                }
                            }
                        }
                        .chart-wrapper{
                            margin-bottom: 16px;
                        }
                        .alert{
                            color: $color_gray;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
            .class-calendar-blk{
                h3{
                    margin-bottom: 4px;
                }
                .classTime{
                    color: $color_info_text;
                }
                .calendar-wrapper{
                    .MuiPickersStaticWrapper-staticWrapperRoot{
                        .MuiPickersBasePicker-container{
                            .MuiPickersBasePicker-pickerView{
                                min-width: 100%;
                                min-height: 100%;
                                .MuiPickersCalendarHeader-switchHeader{
                                    background: rgba(255, 225, 228, 0.2);
                                    .MuiPickersCalendarHeader-iconButton{
                                        background: transparent;
                                        color: $color_black;
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                        .MuiIconButton-label{
                                            .MuiSvgIcon-root{
                                                height: 33px;
                                                width: 33px;
                                            }
                                        }
                                    }
                                    .MuiPickersCalendarHeader-transitionContainer{
                                        p{
                                            @include fontStyle(
                                                $fontFamily:$font_RobotoCondensed,
                                                $size:$font18,
                                                $weight:$boldFont,
                                                $color: $color_primary,
                                                $lineHeight: 24px
                                            );
                                        }
                                    }
                                }
                                .MuiPickersCalendarHeader-daysHeader{
                                    max-height: 20px;
                                    justify-content: space-evenly;
                                    .MuiPickersCalendarHeader-dayLabel{
                                        text-transform: uppercase;
                                        min-width: 13.5%;
                                        max-width: 50px;
                                        margin: 0 auto;
                                        padding-top: 8px;
                                        @include fontStyle(
                                            $fontFamily:$font_RobotoCondensed,
                                            $weight:$boldFont
                                        );
                                    }
                                }
                                .MuiPickersCalendar-transitionContainer{
                                    min-height: 350px;
                                    .MuiPickersCalendar-week{
                                        justify-content: space-evenly;
                                        div[role="presentation"]{
                                            min-width: 14%;
                                            max-width: 50px;
                                            margin: 4px auto;
                                            .MuiButtonBase-root{
                                                .MuiIconButton-label{
                                                    .MuiTypography-root{
                                                        // @include fontStyle(
                                                        //     $fontFamily:$font_RobotoCondensed,
                                                        //     $weight:$boldFont
                                                        // );
                                                    }
                                                }
                                            }
                                            .dance-class-date{
                                                text-align: center;
                                                // height: 100%;
                                                .heading1{
                                                    font-size: 30px;
                                                    margin-bottom: 0;
                                                }
                                                .classTime{
                                                    color: $color_success;
                                                }
                                                .review{
                                                    .feedback{
                                                        color: $color_blue;
                                                        margin-right: 10px;
                                                    }
                                                    .recording{
                                                        color: $color_primary;
                                                    }
                                                }
                                                &.over, &.absent{
                                                    .heading1{
                                                        color: $color_error;
                                                    }
                                                    .classTime{
                                                        color: $color_error;
                                                    }
                                                }
                                                &.attended{
                                                    .heading1{
                                                        color: $color_success;
                                                    }
                                                    .classTime{
                                                        color: $color_success;
                                                    }
                                                }
                                                &.today{
                                                    border: 1px solid #999999;
                                                }
                                                &.future{
                                                    .heading1{
                                                        color: $color_black;
                                                    }
                                                    .classTime{
                                                        color: $color_black;
                                                    }
                                                }
                                            }
                                            .disabled-date{
                                                text-align: center;
                                                min-width: 14%;
                                                max-width: 50px;
                                                margin: 0 auto;
                                                pointer-events: none;
                                                .heading1{
                                                    color: $color_light_gray;
                                                }
                                                &.today{
                                                    border: 1px solid #999999;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .graph-info{
                        .alert{
                            margin-bottom: 16px;
                        }
                        .indicators{
                            // display: flex;
                            margin-bottom: 16px;
                            li{
                                margin-right: 16px;
                                p{
                                    position: relative;
                                    padding-left: 22px;
                                    font-weight: $boldFont;
                                    &::before{
                                        content: '';
                                        left: 0;
                                        width: 16px;
                                        height: 16px;
                                        position: absolute;
                                        border-radius: 2px;
                                    }
                                    &.today{
                                        color: $color_gray;
                                        &::before{
                                            width: 12px;
                                            height: 12px;
                                            border:2px solid $color_info_text
                                        }
                                    }
                                    &.upcoming{
                                        color: $color_black;
                                        &::before{
                                            background: $color_black;
                                        }
                                    }
                                    &.attended{
                                        color: $color_success;
                                        &::before{
                                            background: $color_success;
                                        }
                                    }
                                    &.absent{
                                        color: $color_error;
                                        &::before{
                                            background: $color_error;
                                        }
                                    }
                                }
                            }
                        }
                        .review-feedback-indicators{
                            li{
                                margin-right: 30px;
                                h3{
                                    &.feedback{
                                        color: $color_blue;
                                    }
                                    &.review{
                                        color: $color_primary;
                                    }
                                    span{
                                        margin-left: 10px;
                                        font-size: 12px;
                                        position: relative;
                                        bottom: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .user-progress-form-wrapper{
        form{
            // position: relative;
            padding-bottom: 60px;
            padding-top: 16px;
            .form-title{
                margin-bottom: 16px;
            }
            .footer{
                position: absolute;
                bottom: 0;
                width: 100%;
                right: 0;
                box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
                p{
                    margin: 12px 16px;
                }
            }
            .alert{
                color: $color_gray;
                margin-bottom: 16px;
            }
            &.log-calories-form{
                
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .user-progress-section{
            .user-progress-container{
                padding: 0px 150px 64px 150px;
                .wrapper{
                    padding: 32px 16px 32px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
@media (min-width: 1279px){
    body{
        .user-progress-section{
            .user-progress-container{
                padding: 0px 250px 0 250px;
                .wrapper{
                    padding: 64px 16px 64px;
                    margin-bottom: 8px;
                }
            }
        }
        .user-progress-form-wrapper{
            form{
                .footer{
                    bottom: 32px;
                }
            }
        }
    }
}