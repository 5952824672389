@import 'variable';
body{
    .booking-section{
        padding: 16px 0;
        .booking-container{
            padding: 0;
            .metaText{
                padding: 0 16px;
                margin-bottom: 8px;
            }
            .dance-information-card{
                padding: 0 16px;
                border-bottom: 1px solid #FFE5E9;
            }
            .dance-attributes{
                padding: 0 16px;
                border-bottom: 1px solid #FFE5E9;
                p{
                    margin-bottom: 8px;
                }
                h3{
                    &.cost{
                        span{
                            text-decoration: line-through;
                            color: $color_info_text;
                            font-weight: $regularFont;
                            margin-right: 10px;
                            font-size: $font14;
                        }
                    }
                }
                .timeWrapper{
                    padding: 16px 0;
                    border-right: 1px solid #FFE5E9;
                }
                .amountWrapper{
                    padding: 16px 0 16px 16px;
                }
            }
            .login-button-wrapper{
                padding: 16px 16px 0;
                // border-bottom: 1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .login-link{
                    a{
                        color: $color_blue;
                        text-decoration: underline;
                    }
                }
            }
            .logged-in-user-wrapper{
                padding: 16px;
                border-bottom: 1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .user-profile{
                    .user-avatar{
                        width: 64px;
                        height: 64px;
                        margin-right: 16px;
                        background: $color_primary;
                        color: $color_white;
                        font-size: 30px;
                        text-transform: capitalize;
                    }
                    .displayName{
                        // text-transform: capitalize;
                        margin-bottom: 4px;
                    }
                    .logout-link{
                        a{
                            color: $color_blue;
                        }
                    }

                }
                .booking-fixed-footer{

                }
                form{
                    margin-top: 24px;
                    .form-title{
                        margin-bottom: 16px;
                        .heading3{
                            margin-bottom: 4px;
                        }
                        .paragraph{
                            color: $color_gray;
                        }
                    }
                }
            }
            .booking-fixed-footer{
                padding: 12px 16px;
                margin: 0 -16px;
                box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
                position: fixed;
                bottom: 0;
                width: 100%;
                background: #fff;
                box-sizing: border-box;
                z-index: 9;
            }
            .user-information-form-wrapper{
                padding: 16px;
                .form-title{
                    margin-bottom: 16px;
                    h3{
                        margin-bottom: 4px;
                        color: $color_gray;
                    }
                    p{
                        color: $color_gray;
                    }
                }
                form{
                    .booking-fixed-footer{
                        
                    }
                }
            }
            .subscription-information-card{
                // padding: 0 16px 16px;
                // border-bottom: 1px solid #FFE5E9;
            }
            .payment-options-wrapper{
                padding: 16px;
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .booking-section{
            padding-top: 0;
            padding-bottom: 64px;
            .booking-container{
                padding: 64px 250px 0;
                margin-right: 300px;
                .dance-information-card{
                    .bottom-blk{
                        padding-bottom: 24px;
                    }
                }
                .dance-attributes{
                    .timeWrapper{
                        padding: 24px 0;
                    }
                    .amountWrapper{
                        padding: 24px 0 24px 16px;
                    }
                }
                .login-button-wrapper{
                    padding: 24px 16px 0;
                }
                .user-information-form-wrapper{
                    padding: 24px 16px;
                }
            }  
        }     
    }
}