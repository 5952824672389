@import 'variable';

body{
    .help-section{
        overflow: hidden;
        .help-container{
            padding: 0;
            .heading2{
                &.title{
                    margin-bottom: 16px;
                }
            }
            .who-we-are-blk{
                padding-top: 16px;
                .bg-img{
                    top: 0;
                }
            }
            .how-it-works-blk{
                border-bottom: 1px solid #FFE5E9;
                margin-bottom: 24px;
            }
            .add-to-home-screen-blk, .invite-friends-blk{
                padding: 0 16px 24px;
                border-bottom: 1px solid #FFE5E9;
                margin-bottom: 24px;
                .inner-wrapper{
                    .primaryBtn{
                        background: $color_white;
                        border: 1px solid $color_blue;
                        color: $color_blue;
                    }
                }
            }
            .contact-us-blk{
                padding-bottom: 0;
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .help-section{
            .help-container{
                .who-we-are-blk{
                    padding-top: 64px;
                    .bg-img{
                        top: 70px;
                    }
                }
                .how-it-works-blk{
                    margin-bottom: 0;
                }
                .add-to-home-screen-blk, .invite-friends-blk{
                    margin: 0 150px;
                    padding: 64px 0;
                }
                .common-questions-blk{
                    padding: 64px 0 0;
                    margin-bottom: 0;
                }
                .contact-us-blk{
                    padding-bottom: 90px;
                }
            }
        }
    }
}
@media (min-width: 1279px){
    body{
        .help-section{
            .help-container{
                .add-to-home-screen-blk, .invite-friends-blk{
                    margin: 0 200px;
                }
            }
        }
    }
}
