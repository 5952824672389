@import 'variable';

body{
    .schedule-section{
        .schedule-container{
            padding: 0;
            .dance-listing-wrapper{
                .date-picker-wrapper{
                    position: sticky;
                    position: -webkit-sticky;
                    top: 64px; /* required */
                    background: #fff;
                    padding: 16px 0 16px 0px;
                    z-index: 999;
                    .time-slots-tabs-wrapper{
                        .time-slots-tabs{
                            margin-bottom: 0;
                            .MuiTabs-scrollable{
                                .MuiTabs-flexContainer{
                                    ul{
                                        display: flex;
                                        list-style: none;
                                        // padding-top: 28px;
                                        li{
                                            a{
                                                &.is-current{
                                                    .date-item-wrapper{
                                                        .day-wrapper{
                                                            box-shadow: none;
                                                            background: $color_primary;
                                                            .heading1,.secondaryText{
                                                                color: $color_white;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &:first-child{
                                                padding-left: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .dance-item-date-wrapper{
                    .title{
                        color: $color_primary;
                        margin: 0 16px;
                        overflow: hidden;
                        &::after{
                            content:'';
                            display:inline-block;
                            width:100%; 
                            height:100%;
                            margin-right:-100%;
                            border-bottom:4px solid #FFE5E9;
                            margin-left: 16px;
                            margin-bottom: 5px;
                        }
                    }
                    .dance-information-card{
                        padding: 24px 16px 8px;
                        border-bottom: 4px solid #FFE5E9;
                        .bottom-blk{
                            position: relative;
                            .cost{
                                position: absolute;
                                right: 0;
                                top: 0;
                                color: $color_black;
                                text-align: right;
                                span{
                                    text-decoration: line-through;
                                    color: $color_info_text;
                                    font-weight: $regularFont;
                                    display: block;
                                    line-height: 15px;
                                    font-size: $font14;
                                }
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
                .list-footer{
                    padding: 24px 24px 44px;
                    img{
                        width: 32px;
                        margin-bottom: 5px;
                    }
                    p{
                        color: $color_info_text
                    }
                }
            }
            .schedule-skeleton-wrapper{
                padding: 16px;
                .date-wrapper{
                    display: flex;
                    overflow: scroll;
                    .date-item{
                        margin-right: 8px;
                        border-radius: 8px;
                    }
                }
                .MuiSkeleton-text{
                    border-radius: 8px;
                }
                .dance-card-skeleton{
                    margin-top: 32px;
                    .MuiSkeleton-root{
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .schedule-section{
            .schedule-container{
                padding: 0px 250px 0 250px;
                .dance-listing-wrapper{
                    .date-picker-wrapper{
                        // padding: 32px 0
                    }
                }
            }
        }
    }
}
@media (min-width: 1279px){
    body{
        .schedule-section{
            .schedule-container{
                .dance-listing-wrapper{
                    .date-picker-wrapper{
                        // padding: 32px 0
                    }
                }
            }
        }
    }
}