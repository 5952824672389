@import 'variable';

body{
    margin: 0;
    .App{
        padding-top: 64px;
    }
    .errorBoundary{
        min-height: 80vh;
        background: url("https://letzdance-fe.s3.us-east-2.amazonaws.com/main_bg_image.svg");
        .tech-error-img{
            margin-bottom: 16px;
        }
        h3{
            color: $color_gray;
            margin-bottom: 24px;
        }
        .primaryBtn{
            width: 140px;
        }
    }
    header{
        position: fixed;
        top: 0;
        left: 0;
        height: 64px;
        background: $color_primary;
        text-align: center;
        vertical-align: middle;
        width: 100%;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        .heading2{
            color: $color_white;
            // text-transform: capitalize;
        }
        .back-arrow{
            position: absolute;
            left: 20px;
            cursor: pointer;
        }
        .whatsappFlotingBtn{
            a{
                position: fixed;
                width: 60px;
                height: 60px;
                bottom: 40px;
                right: 25px;
                background-color: #25d366;
                color: #FFF;
                border-radius: 50%;
                text-align: center;
                font-size: 30px;
                box-shadow: 2px 2px 3px #999;
                z-index: 100;
                svg{
                    fill: #fff;
                    padding: 5px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 60px;
                }
            }
            &.hoc{
                a{
                    bottom: 84px;
                }
            }
        }
    }
    .bottom-navigation{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 4px 0;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        .MuiBottomNavigationAction-root{
            // padding: 6px 6px 8px;
            // min-width: 55px;
            .MuiBottomNavigationAction-wrapper{
              img{
                width: 24px;
                margin-bottom: 4px;
              }
              .MuiBottomNavigationAction-label{
                @include fontStyle(
                  $fontFamily:$font_RobotoCondensed,
                  $size:$font12,
                  $weight:$regularFont,
                  $color: $color_info_text,
                  $lineHeight: $lineHeight16
                );
                &.Mui-selected{
                    color: $color_primary;
                    font-weight: $boldFont;
                }
              }
            }
          }
    }
    .partially-visible-carousel-container{
        .carousel-item{
            padding-right: 16px;
            box-sizing: border-box;
        }
    }
    .react-multi-carousel-list{
        .react-multi-carousel-track{
            .react-multi-carousel-item {
                &:first-child{
                    margin-left: 16px;
                }
                &:last-child{
                    padding-right: 32px;
                }
            }
        }
    }
    .custom-dot-list{
        bottom: 16px;
        .react-multi-carousel-dot{
            button{
                background: #CCCCCC;
                border-color: #CCCCCC;
                height: 8px;
                width: 8px;
            }
            &.react-multi-carousel-dot--active{
                button{
                    background: $color_primary;
                    border-color: $color_primary;
                    border-radius: 8px;
                    width: 24px;
                }
            }
        }
    }
    .react-multiple-carousel__arrow{
        background: $color_white;
        min-width: 38px;
        min-height: 38px;
        // border: 1px solid $color_primary;
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.3);
        &::before{
            font-size: 18px;
            color: $color_primary
        }
        &:hover{
            background: $color_white;
        }
    }
    .react-multiple-carousel__arrow--right{
        right: 8px;
    }
    .react-multiple-carousel__arrow--left{
        left: 8px;
    }
    .who-we-are-blk{
        padding-left: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid $color_FFE5E9;
        margin-bottom: 24px;
        position: relative;
        .bg-img{
            position: absolute;
            width: 89px;
            transform: rotateY(180deg);
            top: -16px;
            right: -22px;
            opacity: 0.1;
        }
    }
    .why-letzdance-blk{
        padding-left: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid $color_FFE5E9;
        margin-bottom: 24px;
        position: relative;
        .list-wrapper{
            .list-item{
                margin-left: -8px;
                margin-right: 8px;
                display: flex;
                align-items: center;
                border: 1px solid #DDDDDD;
                padding: 12px 8px;
                height: 100%;
                box-sizing: border-box;
                img{
                    width: 32px;
                    margin-right: 16px;
                }
                // margin-bottom: 8px;
                border-radius: 4px;
                p{
                    color: $color_gray;
                }
                &.point-1{
                    background: rgba(255, 225, 228, 0.2);
                }
                &.point-2{
                    background: rgba(255, 243, 207, 0.2);
                }
                &.point-3{
                    background: rgba(250, 223, 255, 0.2);
                }
                &.point-4{
                    background: rgba(226, 255, 223, 0.2);
                    margin-bottom: 0;
                }
            }
        }
    }
    .how-it-works-blk{
        padding: 0 16px 24px;
        .how-works-list{
            .list-item-grid{
                padding-top: 0;
                &:first-child{
                    padding-top: 8px;
                }
                &:last-child{
                    .list-item{
                        margin-bottom: 0;
                    }
                }
                .list-item{
                    .icon{
                        margin-right: 16px;
                        width: 32px;
                    }
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                }
                .down-arrow{
                    padding-left: 12px;    
                }
          }
        }
    }
    .common-questions-blk{
        margin-bottom: 24px;
        padding: 0 16px 24px;
        .accordion-outer-wrapper{
            .accrodion-item-wrapper{
                .accordion-item{
                    .common-questions-accordion{
                        border: 1px solid #999999;
                        box-sizing: border-box;
                        border-radius: 4px;
                        box-shadow: none;
                        .MuiAccordionDetails-root{
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
    .contact-us-blk{
        padding: 0 16px 30px;
        text-align: center;
        ul{
            margin-bottom: 18px;
            li{
                padding-right: 24px;
                &:last-child{
                    padding: 0;
                }
            }
        }
        p{
            color: $color_info_text;
        }
    }
    .instructor-card{
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
        border-radius: 8px;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        .title-blk{
            padding: 26px 16px 24px;
            border-bottom: 1px solid #DDDDDD;
            position: relative;
            .profile-img{
                position: absolute;
                top: 16px;
                border: 1px solid #CCCCCC;
                border-radius: 50%;
                width: 64px;
                height: 64px;
            }
            h3,p{
                margin-left: 80px;
            }
            h3{
                margin-bottom: 6px;
            }
            p{
                color: $color_primary;
                img, span{
                    vertical-align: middle;
                }
                img{
                    margin-top: -4px;
                    position: static;
                    margin-right: 5px;
                    width: 18px;
                }
                .rating{
                    font-size: $font12;
                    margin-left: 5px;
                }
            }
            color: $color_primary;
                            
        }
        .info-blk{
            padding: 16px;
            // height: 133px;
            box-sizing: border-box;
            p{
                margin-bottom: 8px;
                &:last-child{
                    margin: 0;
                }
                img, span{
                    vertical-align: middle;
                }
                img{
                    margin-right: 5px;
                    width: 18px;
                }
                span{
                    &.live{
                        background: $color_primary;
                        padding: 0 4px;
                        font-size: 6px;
                        color: $color_white;
                        border-radius: 1px;
                        margin-right: 5px;
                        margin-top: 2px;
                        line-height: 12px;
                    }
                }
            }
            .description{
                display: flex;
                align-items: flex-start;
            }
            .read-more{
                margin-left: 24px !important;
                margin-top: -8px !important;
                span{
                    color: $color_primary;
                }
                img{
                    width: 7px;
                    margin: 4px 0 0 5px;
                }
            }
        }
    }
    .review-card{
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
        border-radius: 8px;
        border: 1px solid $color_light_gray;
        height: 100%;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        .content-block{
            padding: 16px;
            border-bottom: 1px solid $color_light_gray;
            box-sizing:border-box;
            flex-grow: 1; 
            .img-block{
                margin-bottom: 16px;
                .quote-icon{

                }
                .star-icon-wrapper{
                    float: right;
                    margin-top: 5px;
                    img{
                        margin-right: 5px;
                        margin-top: -3px;
                        width: 18px;
                    }
                    span{
                        color: $color_primary;
                    }
                    span, img{
                        vertical-align: middle;
                    }
                }
            }
            .text{
                margin-bottom: 8px;
            }
            .read-more{
                margin-bottom: 8px;
                color: $color_primary;
                cursor: pointer;
                span,img{
                    vertical-align: middle;
                }
                img{
                    margin-left: 4px;
                    width: 7px;
                    margin-top: 1px;
                }
            }
        }
        .user-info{
            position: relative;
            padding: 16px 16px;
            .user-avatar{
                position: absolute;
                left: 16px;
                top: 16px;
                width: 36px;
                background: $color_primary;
                color: $color_white;
                height: 36px;
                font-size: 16px;
            }
            .name-wrapper{
                padding-left: 44px;
                h3{
                    margin-bottom: 4px;
                }
                p{
                    color: $color_gray;
                }
            }
        }
        &.video{

        }
    }
    .time-slots-wrapper{
        margin-bottom: 16px;
        .secondaryText{
            margin-bottom: 8px;
        }
        ul{
            display: flex;
            li{
                margin-right: 8px;
                p{
                    text-align: center;
                    margin-top: 6px;
                    &.alert_red{
                        color: $color_primary;
                    }
                    &.alert_orange{
                        color: $color_warning;
                    }
                }
            }
        }
    }
    .dance-information-card{ //dance information
        .top-blk{
            margin-bottom: 16px;
            .logo{
                width: 72px;
                height: 72px;
                border-radius: 4px;
            }
            .content{
                padding-left: 16px;
                .heading2{
                    color: $color_black;
                    margin-bottom: 8px;
                    text-transform: capitalize;
                }
                .heading3{
                    color: $color_primary;
                    img, span{
                        vertical-align: middle;
                    }
                    img{
                        margin-top: -2px;
                        width: 18px;
                    }
                    span{
                        margin-left: 5px;
                        &.rating{
                            font-size: $font12;
                        }
                    }
                }
            }
        }
        .bottom-blk{
            position: relative;
            padding-bottom: 16px;
            li{
                margin-bottom: 4px;
                &:last-child{
                    margin: 0;
                }
                .paragraph{
                    img, span{
                        vertical-align: middle;
                    }
                    img{
                        margin-right: 5px;
                        width: 18px;
                    }
                    .live{
                        background: $color_primary;
                        padding: 1px 4px 2px;
                        font-size: 6px;
                        color: $color_white;
                        border-radius: 1px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .time-slots-tabs-wrapper{
        .time-slots-tabs{
            position: relative;
            margin-bottom: 24px;
            .MuiTabScrollButton-root{
                position: absolute;
                z-index: 999;
                &:last-child{
                    right: 5px;
                    left: unset;
                    &::before{
                        right: -15px;
                        left: -60px;
                        background-image: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 46.07%, rgba(255, 255, 255, 0) 100%);
                    }
                }
                left: 5px;
                top: 35px;
                width: 16px;
                height: 16px;
                color: $color_primary;
                svg{
                    display: none;
                    border-radius: 50%;
                    border: 1px solid $color_primary;
                    width: 16px;
                    height: 16px;
                    background: $color_white;
                }
                &::before{
                    // border-radius: 100%;
                    content: '';
                    background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 46.07%, rgba(255, 255, 255, 0) 100%);
                    top: -40px;
                    left: -20px;
                    bottom: -30px;
                    right: -60px;
                    position: absolute;
                    z-index:-1;
                }
            }
            .MuiTabs-scrollable{
                .MuiTabs-flexContainer{
                    .MuiTab-textColorInherit{
                        opacity: 1;
                        min-width: unset;
                        padding: 0;
                        padding-right: 10px;
                        &.Mui-selected{
                            .date-item-wrapper{
                                .day-wrapper{
                                    box-shadow: none;
                                    background: $color_primary;
                                    .heading1,.secondaryText{
                                        color: $color_white;
                                    }
                                }
                            }
                        }
                    }
                    .date-item-wrapper{
                        .month{
                            color: $color_primary;
                            margin-bottom: 0px;
                        }
                        .day-wrapper{
                            min-width: 50px;
                            box-sizing: border-box;
                            padding: 3px 7px;
                            border-radius: 8px;
                            border: 1px solid #FFE5E9;
                            box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                        }
                        .secondaryText{
                            color: $color_primary;
                        }
                    }
                }
                .MuiTabs-indicator{
                    background-color: transparent;
                }
            }
        }
        .time-slots-tab-panel-wrapper{

        }
    }
    .custom-drawer{
        z-index: 9999 !important;
        .MuiDrawer-paper{
            border-radius: 4px 4px 0 0;
            padding: 16px;
            &::before{
                content: "";
                width: 110px;
                height: 5px;
                margin: 0 auto;
                background: #CCCCCC;
                border-radius: 10px;
                margin-top: -10px;
            }
        }
        &.dance-detail{
            .MuiDrawer-paper{
                padding-bottom: 0;
            }
        }
        &.progress{
            
        }
    }
    .auth-popup-wrapper{
        // .line{
        //     width: 110px;
        //     height: 5px;
        //     margin: 0 auto;
        //     background: #CCCCCC;
        //     border-radius: 10px;
        //     margin-top: -10px;
        // }
        .heading2{
            margin-top: 24px;
            margin-bottom: 22px;
        }
        .loginBtnGroup{
            li{
                margin-bottom: 16px;
                &:last-child{
                    margin: 0;
                }
                .btn{
                    width: 100%;
                    cursor: pointer;
                    @include fontStyle(
                        $fontFamily:$font_RobotoCondensed,
                        $size:$font14,
                        $weight:$boldFont,
                        $color: $color_white,
                        $lineHeight: $lineHeight16
                    );
                    padding: 12px 32px;
                    box-sizing: border-box;
                    border-radius: 4px ;
                    border: 1px solid transparent; 
                    outline: none;
                    &.phone{
                        background: #0E7ACB;
                    }
                    &.facebook{
                        background: #4267B2;
                    }
                    &.google{
                        background: #4285F4;
                    }
                    &.email{
                        border: 1px solid #0E7ACB;
                        color: #0E7ACB;
                        background: #fff;
                    }
                }
            }
        }
        .auth-outer-wrapper{
            &.phone{
                .paragraph{
                    &.info{
                        color: $color_gray;
                        margin-bottom: 25px;
                        span{
                            color: $color_blue;
                            cursor: pointer;
                        }
                    }
                }
                .inputGroup{
                    position: relative;
                    .loader{
                        position: absolute;
                        right: 10px;
                        top: 30px;
                        color: #DC354B;
                        width: 24px !important;
                        height: 24px !important;
                    }
                    label{
                        &.otp{
                            color: $color_black;
                            span{
                                color: $color_error;
                            }
                        }
                    }
                }
            }
            .footer{
                padding: 12px 16px;
                margin: 0 -16px -16px;
                box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
                li{
                    width: 50%;
                    box-sizing: border-box;
                    padding-right: 8px;
                    &:last-child{
                        padding-right: 0;
                    }
                }
            }
            &.email{
                .signup-link{
                    margin-bottom: 16px;
                    p{
                        a{
                            color: $color_blue;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        &.review{
            .heading2{
                margin: 16px 0;
            }
            .review-card{
                border: none;
                box-shadow: none;
                margin: 0 -16px;
                display: block;
                .content-block{
                    padding-top: 0;
                    min-height: unset;
                }
                .user-info{
                    padding: 16px;
                    .user-avatar{
                        top: 16px;
                    }
                }
            }
        }
        &.recap{
            .custom-video{
                margin-bottom: 8px;
            }
            .class-info{
                margin-bottom: 20px;
                .heading3{
                    margin-bottom: 8px;
                }
            }
        }
        &.instructor-video{
            .class-info{
                .description{
                    margin-top: 8px;
                }
            }
        }
    }
    .book-trial-wrapper{
        .heading2{
            margin-top: 24px;
            margin-bottom: 22px;
        }
        .footer{
            padding: 12px 16px;
            margin: 0 -16px -16px;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
            li{
                width: 50%;
                box-sizing: border-box;
                padding-right: 8px;
                &:last-child{
                    padding-right: 0;
                }
            }
        }
        .radioGroupInput{
            margin-bottom: 32px;
            .radioGroup{
                
            }
        }
    }
    .radioGroup{
        flex-direction: row;
        label{
            .MuiButtonBase-root{
                padding: 0 9px;
            }
            &:last-child{
                margin: 0;
            }
            .label{
                p{
                    color: $color_gray;
                }
                &.active{
                    p{
                        color: $color_black;
                    }
                }
            }
        }
    }
    .footer-review-card{
        padding: 12px 16px;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
        margin: 0 -16px -16px -16px;
    }
    .custom-video{
        width: 100%; 
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
        border-radius: 8px;
        border: 1px solid $color_light_gray;       
    }
    .dance-alert-wrapper{
        padding: 16px;
        border-radius: 8px;   
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
        background: $color_primary;
        margin-top: 16px;
        .heading3{
            margin-bottom: 32px;
            color: $color_white;
        }
        .paragraph{
            margin-bottom: 24px;
            color: $color_white;
        }
        .alert-info{
            .secondaryText{
                color: $color_white;
            }
            .secondaryBtn{
                border-color: transparent;
            }
        }
        &.subscription{
            background : $color_400190;
            .heading3{
                margin-bottom: 8px;
                color: $color_white;
            }
        }
    }
    .subscription-alert-wrapper{
        margin: 16px 16px ;
        padding: 16px;
        border-radius: 8px;   
        background: $color_white;
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
        h3{
            margin-bottom: 8px;
        }
        .paragraph{
            margin-bottom: 16px;
        }
        &.active{
            .primaryBtn{
                background: $color_400190;
                border-color: transparent;
            }
            border: 1px solid $color_light_gray;
        }
        &.expire-soon{
            background: $color_400190;
            h3, .paragraph, .secondaryText{
                color: $color_white;
            }
            .primaryBtn{
                background: $color_white;
                color: $color_blue;
                border-color: transparent;
            }
            .alert-info{

            }
        }
    }
    .add-to-home-screen-blk, .invite-friends-blk{
        .inner-wrapper{
            padding: 16px;
            box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
            border-radius: 8px;
            border: 1px solid $color_light_gray;
            box-sizing: border-box;
            .heading3{
                margin-bottom: 8px;
            }
            .paragraph{
                margin-bottom: 16px;
                color: $color_gray;
            }
        }
    }
    .invite-friends-blk{
        .inner-wrapper{
            
        }
    }
    .custom-toast-wrapper{
        .MuiSnackbar-anchorOriginBottomCenter{
            bottom: 72px;
            z-index: 99999;
            .MuiSnackbarContent-root{
                background: $color_success;
                flex-grow: inherit;
                box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                border-radius: 4px;
                .MuiSnackbarContent-message{
                    padding: 5px 0;
                    .toast-message{
                        p{
                            span{
                                @include fontStyle(
                                    $fontFamily:$font_RobotoCondensed,
                                    $size:$font12,
                                    $weight:$boldFont,
                                    $color: $color_white,
                                    $lineHeight: $lineHeight16
                                );
                                vertical-align: middle;
                            }
                            img{
                                vertical-align: middle;
                                margin-right: 8px;
                                width: 14px;
                            }
                        }
                    }
                }
            }
        }
        &.error{
            .MuiSnackbar-anchorOriginBottomCenter{
                .MuiSnackbarContent-root{
                    background: $color_error;
                }
            }
        }
    }
    .auth-verify-wrapper{
        margin-top: 16px;
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
        border-radius: 8px;
        border: 1px solid $color_light_gray;
        padding: 16px;
        .heading3{
            margin-bottom: 8px;
        }
        .paragraph{
            color: $color_gray;
            margin-bottom: 16px;
        }
    }
    .screen-loader-wrapper{
        position: absolute;
        top: 50%;
        left: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        .MuiCircularProgress-root{
            color: $color_primary;
        }
    }
    .dance-information-loader{
        padding: 0 16px;
        .MuiSkeleton-root{
            border-radius: 8px;
        }
    }
    .subscription-information-card{
        padding: 0 16px 16px;
        border-bottom: 1px solid #FFE5E9;
        .top-blk{
            margin-bottom: 16px;
            .logo{
                width: 72px;
                height: 72px;
                border-radius: 4px;
            }
            .content{
                padding-left: 16px;
                .heading2{
                    color: $color_black;
                    margin-bottom: 8px;
                    text-transform: capitalize;
                }
                .heading3{
                    color: $color_primary;
                    img, span{
                        vertical-align: middle;
                    }
                    img{
                        margin-top: -2px;
                        width: 18px;
                    }
                    span{
                        margin-left: 5px;
                        &.rating{
                            font-size: $font12;
                        }
                    }
                }
            }
        }
        .bottom-blk{
            ul{
                li{
                    margin: 0 0 8px;
                    img, span{
                        vertical-align: middle;
                    }
                    img{
                        margin-right: 10px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.more-point{
                        margin-left: 22px;
                        color: $color_blue;
                    } 
                }
            }
        }
    }
    .subcription-card-wrapper{
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid $color_light_gray;
        margin-bottom: 1px;
        position: relative;
        background-repeat: round;
        background-size: contain;
        .activeLabel{
            position: absolute;
            background: $color_success;
            padding: 3px 12px;
            border: 1px solid $color_white;
            color: $color_white;
            box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
            border-radius: 4px;
            right: 8px;
            top: 8px;
            &.danger{
                background: $color_error;
            }
        }
        .info-wrapper{
            margin-top: 90px;
            background: $color_white;
            padding: 12px 16px;
            .heading3{
                text-transform: capitalize;
                margin-bottom: 8px;
            }
            .benefitsList{
                padding: 16px 0;
                min-height: 75px;
                box-sizing: border-box;
                li{
                    margin: 0 0 8px;
                    padding: 0;
                    img, span{
                        vertical-align: middle;
                    }
                    img{
                        margin-right: 10px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.more-point{
                        margin-left: 22px;
                    }
                }
            }
            .activeStatus{
                background-color: $color_400190;
            }
        }
        .buttonWrapper{
            flex-flow: row nowrap;
            justify-content: space-between;
            a{
                max-width: 49%;
            }
        }
    }
    .MuiRadio-root{
        &.Mui-checked{
            color: $color_primary !important;
        }
    }
    .MuiPopover-root{
        z-index: 99999 !important;
        .MuiPickersBasePicker-container{
            .MuiPickersBasePicker-pickerView{
                .MuiPickersCalendarHeader-switchHeader{
                    .MuiPickersCalendarHeader-daysHeader, .MuiPickersCalendarHeader-transitionContainer{
                        .MuiTypography-root{
                            @include fontStyle(
                                $fontFamily:$font_RobotoCondensed,
                            );
                        }
                    }
                }
                .MuiPickersCalendar-transitionContainer{
                    .MuiPickersCalendar-week{
                        .MuiIconButton-root{
                            .MuiIconButton-label{
                                .MuiTypography-root{
                                    @include fontStyle(
                                        $fontFamily:$font_RobotoCondensed,
                                        $weight: $boldFont
                                    );
                                }
                            }
                            &.MuiPickersDay-daySelected{
                                background-color: $color_primary;   
                            }
                            &.MuiPickersDay-dayDisabled{
                                .MuiIconButton-label{
                                    .MuiTypography-root{
                                        font-weight: 300;
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .custom-dialog{
        .content-wrapper{
            padding: 40px 20px;
        }
       &.share-link-dialog{
        .content-wrapper{
            padding-bottom: 32px;
            .inputGroup{
                margin-bottom: 24px;
                .copyBtn{
                    position: absolute;
                    top: 48px;
                    right: 25px;
                    max-width: 70px;
                    padding: 5px 16px;
                }
            }
            .share-buttons-list{
                // padding: 0;
                // li{
                //     padding: 0 15px 0 0;
                // }
                text-align: center;
                button{
                    padding: 0 8px 8px 0 !important;
                    &:last-child{
                        padding: 0 !important;
                    }
                    &:hover{
                        opacity: 0.8;
                    }
                }
            }
        }
       } 
    }
}