@import 'variable';
body{
    .reviews-listing-section{
        .reviews-listing-container{
            padding: 16px;
            .reviews-listing-loader{
                .reviews-listing-item{
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border-radius: 8px;
                    border: 1px solid $color_light_gray;
                }
            }
        }
    }
}

@media (min-width: 960px) and (max-width: 1279px){
    body{
        .reviews-listing-section{
            .reviews-listing-container{
                padding: 64px 200px 0;
            }
        }
    }
}
@media (min-width: 1279px){
    body{
        .reviews-listing-section{
            .reviews-listing-container{
                padding: 64px 150px 0;
            }
        }
    }
}