@import 'variable';
body{
    .profile-section{
        .profile-container{
            padding: 0;
            .top-blk{
                background: url("https://letzdance-fe.s3.us-east-2.amazonaws.com/profile_bg_img.svg");
                min-height: 200px;
                background-repeat: repeat-x;
                padding: 0 16px 16px;
                border-bottom:  1px solid #FFE5E9;
                .login-btn-wrapper{
                    padding-top: 110px;
                    .heading3{
                        color: $color_primary;
                        margin-bottom: 16px;
                    }
                }
                .logged-in-user-info{
                    padding-top: 16px;
                    text-align: center;
                    .user-avatar{
                        width: 128px;
                        height: 128px;
                        margin: 0 auto;
                        margin-bottom: 16px;
                        background: $color_primary;
                        color: $color_white;
                        font-size: 60px;
                        text-transform: capitalize;
                        border: 1px solid $color_primary;
                    }
                    .edit-button-wrapper{
                        max-width: 35px;
                        box-sizing: border-box;
                        padding: 8px 6px 3px 9px;
                        border: 1px solid $color_primary;
                        border-radius: 50%;
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
                        margin: 0 auto;
                        margin-top: -32px;
                        position: relative;
                        left: 46px;
                        bottom: 18px;
                        background: #fff;
                        img{
                            width: 18px;
                        }
                    }
                    .heading3{
                        margin-bottom: 8px;
                    }
                    .paragraph{
                        color: $color_gray;
                    }
                }
                .subscription-alert-wrapper{
                    margin: 16px 0 0;
                }
            }
            .links{
                margin-bottom: 60px;
                li{
                    border-bottom:  1px solid #FFE5E9;
                    position: relative;
                    p{
                        a{
                            padding: 16px;
                            width: 100%;
                            display: inline-block;
                            box-sizing: border-box;
                        }
                    }
                    .arrow{
                        position: absolute;
                        right: 16px;
                        top: 23px;
                        width: 12px;
                    }
                    &.disabled{
                        cursor: not-allowed;
                        pointer-events: none;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

@media (min-width: 960px){
    body{
        .profile-section{
            .profile-container{
                padding: 0px 250px;
            }
        }
    }
}