// @media (min-width: 960px) and (max-width: 1279px) {
@media (min-width: 960px){
    body{
        header{
            text-align: unset;
            display: unset;
            .header-wrapper{
                height: 100%;
                .logo-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // height: 100%;
                }
                .nav-bar{
                    padding-left: 30px;
                    li{
                        display: inline-block;
                        margin-right: 30px;
                        &:last-child{
                            margin-right: 0;
                        }
                        .nav-item{
                            @include transitionForAll($time: 0.4s,$transProp: ease-out);
                            cursor: pointer;
                            padding: 10px 20px;
                            border-radius: 16px;
                            display: flex;
                            align-items: center;
                            img{
                                margin-right: 12px;
                            }
                            .active{
                                display: none;
                            }
                            .in-active{
                                display: block;
                            }
                            span{
                                color: #fff;
                                margin-top: 3px;
                            }
                        }
                        &.active{
                            .nav-item{
                                background: #fff;
                                .active{
                                    display: block;
                                }
                                .in-active{
                                    display: none;
                                }
                                span{
                                    color: $color_primary;
                                }
                            }
                        }
                        &:hover{
                            .nav-item{
                                background: #fff;
                                .active{
                                    display: block;
                                }
                                .in-active{
                                    display: none;
                                }
                                span{
                                    color: $color_primary;
                                }
                            }
                        }
                    }
                }
            }
            .whatsappFlotingBtn{
                a{
                    right: 40px !important;
                    bottom: 40px !important;
                }
            }
        }
        .who-we-are-blk, .why-letzdance-blk, .how-it-works-blk, .common-questions-blk{
            margin: 0 150px;
            padding:64px 0;
        }
        .who-we-are-blk{
            .bg-img{
                top: 64px;
            }
            .paragraph{
                @include fontStyle(
                    $fontFamily:$font_RobotoCondensed,
                    $size:$font16,
                    $weight:$boldFont,
                    $color: $color_black,
                    $lineHeight: $lineHeight20
                );
            }
        }
        .why-letzdance-blk{
            .list-wrapper{
                .list-item{
                    .heading3{
                        color: $color_black;
                    }
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        .how-it-works-blk{
            padding-bottom: 64px;
            margin-bottom: 0;
            .how-works-list{
                .list-item-grid{
                    position: relative;
                    padding-top: 8px;
                    .list-item{
                        margin-bottom: 0;
                        flex-direction: column;
                        .icon{
                            margin-bottom: 16px;
                        }
                        .paragraph{
                            @include fontStyle(
                                $fontFamily:$font_RobotoCondensed,
                                $size:$font16,
                                $weight:$boldFont,
                                $color: $color_black,
                                $lineHeight: $lineHeight20
                            );
    
                        }
                    }
                    .down-arrow{
                        transform: rotate(270deg);
                        position: absolute;
                        top: 9px;
                        right: 0;
                        width: 20px;
                    }
                }
            }
        }
        .common-questions-blk{
            padding: 64px 0 64px;
        }
        .contact-us-blk{
            padding-top: 66px;
            padding-bottom: 90px;
        }
        .custom-dot-list{
            bottom: 58px;
        }
        .auth-popup-wrapper, .book-trial-wrapper{
            height: 100%;
            position: relative;
            .heading2{
                margin: 16px 0 24px 0;
            }
            .line{
                display: none;
            }
            .footer{
                position: absolute;
                width: 100%;
                bottom: 32px;
            }
        }
        .footer-review-card{
            margin: 0 -16px 16px -16px;
        }
        .react-multiple-carousel__arrow{
            border: 1px solid $color_primary;
            box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.2);
            min-width: 32px;
            min-height: 32px;
            &::before{
                font-size: 15px;
            }
        }
        .react-multiple-carousel__arrow--right{
            // right: 2px;
        }
        .react-multiple-carousel__arrow--left{
            // left: 2px;
        }
    }
}
@media (min-width: 1279px){
    body{
        header{
            .header-wrapper{
                .nav-bar{
                    padding-left: 60px;
                }
            }
        }
        .custom-drawer{
            .MuiDrawer-paper{
                border-radius: 0;
                padding: 16px;
                width: 30%;
                &.MuiDrawer-paperAnchorBottom{
                    width: inherit !important;
                }
                &::before{
                    display: none;
                }
            }
        }
        .who-we-are-blk, .why-letzdance-blk, .how-it-works-blk, .common-questions-blk{
            margin: 0 200px;
            padding:64px 0;
        }
    }
}