@import 'variable';
body{
    .dance-history-section{
        .dance-history-container{
            padding: 0;
            .dance-history-loader{
                padding: 16px;
                .dance-history-item{
                    border-radius: 8px;
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border: 1px solid $color_light_gray;
                    margin-bottom: 8px;
                }
            }
            .dance-history-item-wrapper{
                padding: 16px;
                .heading{
                    color: $color_primary;
                    margin-bottom: 16px;
                    overflow: hidden;
                    &::after{
                        content:'';
                        display:inline-block;
                        width:100%; 
                        height:100%;
                        margin-right:-100%;
                        border-bottom:4px solid #FFE5E9;
                        margin-left: 16px;
                        margin-bottom: 5px;
                    }
                }
                .dance-history-card{
                    padding: 16px;
                    border: 1px solid $color_light_gray;
                    box-sizing: border-box;
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border-radius: 8px;
                    margin-top: 16px;
                    .title-block{
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                        img{
                            width: 72px;
                            height: 72px;
                            border-radius: 4px;
                            margin-right: 16px;
                        }
                        .title{
                            margin-bottom: 8px;
                            color: $color_black;
                        }
                    }
                    .rating{
                        img, span{
                            vertical-align: middle;
                        }
                        img{
                            margin-right: 8px;
                            width: 18px;
                        }
                    }
                }
            }
            .end-wrapper{
                margin-top: 32px;
                img{
                    width: 32px;
                }
                .paragraph{
                   color: $color_info_text; 
                }
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .dance-history-section{
            .dance-history-container{
                padding: 64px 250px 64px;
            }
        }
    }
}