@media (min-width: 0px) and ( max-width: 599px){
    body{
        .App{
            padding-bottom: 64px;
        }
    }
}

@media (min-width: 0px) and ( max-width: 374px){//iphone5
    body{

    }
}