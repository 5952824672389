
$font_RobotoCondensed: 'Roboto Condensed', sans-serif;

$lightFont:300;
$regularFont:400;
$mediumFont:500;
$semiBoldFont:600;
$boldFont:700;

$color_black : #222222 ;
$color_white: #ffffff;
$color_primary: #AE0423;
$color_blue: #0E7ACB;
$color_gray: #666666;
$color_light_gray: #DDDDDD;
$color_info_text: #999999;
$color_success: #039445;
$color_error: #F00000;
$color_warning: #F18200;
$color_400191: #400191;
$color_FFE5E9: #FFE5E9;
$color_400190: #400190;
$font34: 34px;
$font32: 32px;
$font30: 30px;
$font26: 26px;
$font24: 24px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;

$lineHeight78: 78px;
$lineHeight48: 48px;
$lineHeight36: 36px;
$lineHeight30: 30px;
$lineHeight28: 28px;
$lineHeight26: 26px;
$lineHeight24: 24px;
$lineHeight22: 22px;
$lineHeight21: 21px;
$lineHeight20: 20px;
$lineHeight18: 18px;
$lineHeight16: 16px;

@mixin fontStyle($fontFamily: null, $size: null, $weight: null, $color: null,  $lineHeight: null, $margin: null, $padding: null){
    @if $fontFamily != null {
      font-family: $fontFamily;
    }
    @if $size != null {
      font-size: $size;
    }
    @if $weight != null {
      font-weight: $weight;
    }
    @if $color != null {
      color: $color;
    }
    @if $lineHeight != null {
      line-height: $lineHeight;
    }
    @if $margin != null {
      margin: $margin;
    }
    @if $padding != null {
      padding: $padding;
    }
  }
  
  @mixin transitionForAll($time: 0.3s,$transProp: ease){
    -webkit-transition: all $time $transProp;
    -moz-transition: all $time $transProp;
    -o-transition: all $time $transProp;
    transition: all $time $transProp;
    transition: all $time $transProp;
  }