@import 'variable';

body{
    .user-weights-section{
        .user-weights-container{
            padding: 0;
            .shimmer-wrapper{
                padding: 16px;
            }
            .weightLogs-lsiting{
                .weight-log-item{
                    position: relative;
                    padding: 12px 16px;
                    border-bottom: 1px solid $color_FFE5E9; 
                    h3{
                        margin-bottom: 4px;
                    }
                    p{
                        color: $color_info_text;
                    }
                    .closeIcon{
                        position: absolute;
                        top: 30px;
                        right: 16px;
                        width: 16px;
                        cursor: pointer;
                    }
                }
            }
            .noResultFound{
                text-align: center;
                margin-top: 64px;
                img{
                    margin-bottom: 8px;
                }
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .user-weights-section{
            .user-weights-container{
                padding: 32px 150px 64px 150px;
                .weightLogs-lsiting{
                    .weight-log-item{
                        padding: 24px 16px;
                        .closeIcon{
                            top: 42px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1279px){
    body{
        .user-weights-section{
            .user-weights-container{
                padding: 32px 250px 0 250px;
            }
        }
    }
}