@import 'variable';

body{
    .dance-detail-section{
        overflow: hidden;
        .dance-detail-container{
            padding: 0;
            .block{
                padding: 16px 0 16px 16px;
                border-bottom: 1px solid #FFE5E9;
            }
            .title{
                position: relative;
                margin-bottom: 16px;
                a{
                    position: absolute;
                    right: 16px;
                    top: 7px;
                    color: $color_primary;
                    span,img{
                        vertical-align: middle;
                    }
                    img{
                        margin-top: 1px;
                    }
                }
            }
            .gallery{
                padding-left: 0;
                .gallery-carousel{
                    .react-multiple-carousel__arrow{
                        top: 30%;
                    }
                    .gallery-skeleton-wrapper{
                        .MuiSkeleton-root{
                            border-radius: 8px;
                        }
                    }
                    .gallery-item{
                        .img-blk{
                            position: relative;
                            margin-bottom: 8px;
                            .img-loader{
                                width: 100%;
                                border-radius: 8px 8px 0 0;
                            }
                            .dance-logo{
                                width: 100%;
                                box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                                border-radius: 8px;
                                border: 1px solid $color_light_gray;
                                height: 170px;
                                // min-height: 150px;
                            }
                            .play-icon{
                                position: absolute;
                                top: 40%;
                                left: 45%;
                            }
                        }
                        .custom-video{
                            margin-bottom: 8px;
                        }
                        .class-info{
                            h3{
                                margin-bottom: 4px;
                            }
                            p{
                                color: $color_gray;
                            }
                        }
                    }
                }
            }
            .dance-info{
                padding: 16px 16px 24px;
                position: relative;
                .dance-info-skeleton-wrapper{
                    
                }
                h3{
                    color: $color_primary;
                    margin-bottom: 8px;  
                    .MuiSkeleton-root{
                        margin-left: 0;
                        display: inline-block;
                    }                  
                    img, span{
                        vertical-align: middle;
                    }
                    img{
                        margin-top: -2px;
                        width: 18px;
                    }
                    span{
                        margin-left: 5px;
                        &.rating{
                            font-size: $font12;
                        }
                    }
                }
                .cost{
                    position: absolute;
                    right: 16px;
                    top: 17px;
                    color: $color_black;
                    text-align: right;
                    span{
                        text-decoration: line-through;
                        color: $color_info_text;
                        font-weight: $regularFont;
                        display: block;
                        line-height: 15px;
                        font-size: $font14;
                    }
                }
                ul{
                    li{
                        margin-bottom: 4px;
                        .MuiSkeleton-root{
                            margin-left: 0;
                            display: inline-block;
                        }
                        &:last-child{
                            margin: 0;
                        }
                        .paragraph{
                            img, span{
                                vertical-align: middle;
                            }
                            img{
                                margin-right: 5px;
                                width: 18px;
                            }
                            .live{
                                background: $color_primary;
                                padding: 1px 4px 2px;
                                font-size: 6px;
                                color: $color_white;
                                border-radius: 1px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .time-slots{
                padding: 16px 16px 24px 16px;
                .time-slots-tabs-wrapper{
                    .time-slots-tabs{
                        margin-right: -16px;
                        margin-left: -16px;
                        .MuiTabs-scrollable{
                            .MuiTabs-flexContainer{
                                .MuiTab-textColorInherit{
                                    &:first-child{
                                        padding-left: 16px;
                                    }
                                }
                            }
                        }   
                    }
                    .time-slots-tab-panel-wrapper{
                        .time-slots-wrapper{
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .see-all-blk{
                    margin-top: 24px;
                    h3{
                        margin-bottom: 8px;
                    }
                    .paragraph{
                        margin-bottom: 16px;
                    }
                    padding: 16px;
                    border: 1px solid $color_light_gray;
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border-radius: 8px;
                }
            }
            .instructor{
                padding: 24px 16px 24px 16px;
            }
            .reviews{
                &.block{
                    padding: 24px 0 44px;
                    position: relative;
                }
                .title{
                    margin-left: 16px;
                }
            }
            .dance-subscription-blk{
                padding: 24px 16px;
                background: $color_primary;
                .title{
                    h3{
                        margin-bottom: 4px;
                        color: $color_white;
                        .activeStatus{
                            display: none;
                        }
                    }
                    p{
                        color: $color_white;
                    }
                }
                .secondaryBtn{
                    border: 1px solid transparent;
                }
                &.active{
                    background: $color_400191;
                    .title{
                        h3{
                            .activeStatus{
                                display: inline-block;
                                float: right;
                                color: $color_white;
                                background: $color_success;
                                padding: 2px 8px;
                                box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                                border-radius: 4px;
                                border: 1px solid $color_white;
                            }
                        }
                    }
                }
                .carousel-container{
                    .react-multi-carousel-track{
                        li{
                            &:first-child{
                                margin-left: 0;
                            }
                        }
                    }
                }
                .subscription-info-card{
                    padding: 32px 0;
                    text-align: center;
                    img{
                        margin-bottom: 24px;
                    }
                    p{
                        color: $color_white;
                    }
                }
            }
            .how-it-works-blk{
                border-bottom: 1px solid #FFE5E9;
                padding-top: 24px;
                margin-bottom: 24px;
            }
            .contact-us-blk{
                padding-bottom: 0;
            }
        }
    }
}
@media (min-width: 600px) and (max-width: 959px) {
    body{
        .dance-detail-section{
            .dance-detail-container{
                .gallery{
                    padding-left: 0;
                    .gallery-carousel{
                        .react-multi-carousel-track {
                            transform: translate3d(16px, 0px, 0px) !important;
                            &:first-child{
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .dance-detail-section{
            .dance-detail-container{
                .block{
                    padding:64px 0;
                    margin: 0 150px;
                }
                .gallery{
                    // padding-top: 32px;
                }
                .dance-subscription-blk{
                    padding:64px 150px;
                }
                .dance-info{
                    .cost{
                        right: 150px;
                        top: 64px;
                    }
                }
                .time-slots{
                    .see-all-blk{
                        margin-top: 32px;
                    }
                }
                .reviews{
                    &.block{
                        padding: 64px 0 84px;
                        margin: 0 134px; 
                    }
                }
                .how-it-works-blk{
                    padding-top: 64px;
                    margin-bottom: 0;
                }
                .contact-us-blk{
                    padding-top: 64px;
                    padding-bottom: 90px;
                }
            }
        }
    }
}
@media (min-width: 1279px){
    body{
        .dance-detail-section{
            .dance-detail-container{
                .block{
                    margin: 0 250px;
                }
                .dance-subscription-blk{
                    padding:64px 250px;
                }
                .dance-info{
                    .cost{
                        right: 250px;
                        top: 64px;
                    }
                }
                .how-it-works-blk{
                    margin: 0 250px; 
                }
                .reviews{
                    &.block{
                        margin: 0 234px;
                    }
                }
            }
        }
    }
}