@import 'variable';
body{
    .class-detail-section{
        .class-detail-container{
            padding: 0;
            .dance-information-loader{
                padding: 16px;
            }
            .dance-information-card{
                padding: 16px 16px 0;
                border-bottom: 1px solid $color_FFE5E9;
            }
            .timeWrapper{
                padding: 16px;
                border-bottom: 1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
            }
            .calories-burnt-info, .part-of-subscription{
                padding: 16px;
                border-bottom: 1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .info{
                    color: $color_gray;
                    margin-top: 4px;
                }
                .heading3{
                    text-transform: capitalize;
                }
            }
            .instructor-feedback{
                padding: 16px;
                border-bottom: 1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
            }
            .class-recording{
                padding: 16px;
                border-bottom: 1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .recording-video-wrapper{
                    cursor: pointer;
                    .img-loader{
                        margin-bottom: 8px;
                        .MuiSkeleton-root{
                            border-radius: 8px;
                            box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                            border: 1px solid $color_light_gray;
                        }
                    }
                    .img-blk{
                        position: relative;
                        .dance-logo{
                            width: 100%;
                            box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                            border-radius: 8px;
                            border: 1px solid $color_light_gray;
                        }
                        .play-icon{
                            position: absolute;
                            top: 40%;
                            left: 45%;
                        }
                    }
                    .class-info{
                        margin-top: 8px;
                        h3{
                            margin-bottom: 4px;
                            text-transform: capitalize;
                        }
                        p{
                            color: $color_gray;
                        }
                    }
                }
            }
            .review-block{
                padding: 16px;
                border-bottom: 1px solid #FFE5E9;
                .review-detail{
                    .secondaryText{
                        margin-bottom: 8px;
                    }
                    .rating{
                        img, span{
                            vertical-align: middle;
                        }
                        span{
                            color: $color_primary
                        }
                        img{
                            margin-right: 8px;
                        }
                    }
                    .description{
                        margin-top: 8px;
                    }
                }
                .add-review-card-wrapper{
                    h3{
                        margin-bottom: 16px;
                    }
                    padding: 16px;
                    border: 1px solid $color_light_gray;
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border-radius: 8px;
                }
            }
            .payment-info{
                padding: 16px;
                border-bottom: 1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .heading3{
                    .cost-old{
                        text-decoration: line-through;
                        color: $color_info_text;
                        margin-right: 8px;
                        font-size: $font14;
                        font-weight: $regularFont;
                    }
                }
            }
            .need-help{
                padding: 16px;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .link{
                    color: $color_gray;
                }
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .class-detail-section{
            .class-detail-container{
                padding: 64px 250px 32px;
            }
        }
    }
}