@import 'variable';

body{
    .subscription-detail-section{
        header{
            .heading2{
                font-size: 24px;
            }
        }
        .subscription-detail-container{
            padding: 0;
            .shimmer-wrapper{
                padding: 16px 16px 24px;
                ul{
                    li{
                        padding: 0 0 16px;
                    }
                }
            }
            .subscription-detail-header{
                .user-subscription-info{
                    border-bottom: 1px solid $color_FFE5E9;
                    padding: 16px 16px 24px;
                    h3{
                        margin-bottom: 16px;
                    }
                    .classInfo{
                        margin-bottom: 16px;
                        .activeLabel{
                            background: $color_success;
                            padding: 2px 8px;
                            color: $color_white;
                            font-size: $font12;
                            box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                            border-radius: 4px;
                            float: right;
                            margin-top: -3px;
                            &.danger{
                                background: $color_error;
                            }
                        }
                    }
                    .progress-bar{
                        margin-bottom: 8px;
                        &.danger{
                            .MuiLinearProgress-barColorPrimary{
                                background-color: $color_error !important;
                            }
                        }
                    }
                    .date{
                        margin-bottom: 16px;
                        span{
                            float: right;
                        }
                    }
                    .link{
                        max-width: 80%;
                        margin: 0 auto;
                    }
                }
                img{
                    width: 100%;
                    max-height: 300px;
                }
                .info-wrapper{
                    padding: 16px;
                    text-align: center;
                    background: rgba(255,225,228,0.2);
                    border-radius: 0 0 4px 4px;
                    h3{
                        margin-bottom: 8px;
                        color: $color_black;
                    }
                    p{
                        margin-bottom: 24px;
                        &.link{
                            // max-width: 80%;
                            // margin: 0 auto;
                            margin-bottom: 8px;
                        }
                    }
                    .buttonWrapper{
                        justify-content: space-between;
                        .link{
                            width: 49%;
                        }
                    }
                }
            }
            .subscription-benefits{
                padding: 24px 16px; 
                .title{
                    margin-bottom: 24px;
                }
                border-bottom: 1px solid $color_FFE5E9;
                .benefits-list{
                    .benefit-item-wrapper{
                        &:last-child{
                            padding-bottom: 8px;
                        }
                        padding: 20px 0;
                        display: flex;
                        align-items: center;
                        img, .MuiSkeleton-root{
                            margin-right: 16px;
                        }
                    }
                }
            }
            .subscription-plans{
                padding: 24px 16px; 
                border-bottom: 1px solid $color_FFE5E9;
                position: relative;
                .title{
                    margin-bottom: 24px;
                }
                .plans-wrapper{
                    border: 1px solid $color_light_gray;
                    box-sizing: border-box;
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border-radius: 8px;
                    margin-bottom: 16px;
                    .plans-item{
                        padding: 16px;
                        border-bottom: 1px solid $color_light_gray;
                        .radioGroup{
                            margin-bottom: 16px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            label{
                                margin-bottom: 16px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                margin-right: 0;
                                align-items: flex-start;
                                .MuiButtonBase-root{
                                    padding-top: 0;
                                }
                                .MuiTypography-root{
                                    .label{
                                        &.active{
                                            .heading3{
                                                color: $color_black;
                                            }
                                        }
                                        .heading3{
                                            color: $color_gray;
                                        }
                                    }
                                }
                            }
                        }
                        .heading3{
                            margin-bottom: 16px;
                        }
                        .label{
                            color: $color_gray;
                            &.active{
                                color: $color_black;
                            }
                            .heading3{
                                margin-bottom: 8px;
                            }
                            p{
                                span{
                                    &.cost-old{
                                        text-decoration: line-through;
                                        color: $color_info_text;
                                        margin-right: 4px;
                                    }
                                    &.offer{
                                        color: $color_success;
                                        margin-left: 24px;
                                    }
                                }
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                            padding-bottom: 36px;
                        }
                    }
                }
                .link{
                    width: 80%;
                    position: absolute;
                    bottom: 20px;
                    left: 10%;
                }
            }
            .class-booking-alert{
                padding: 24px 16px; 
                border-bottom: 1px solid $color_FFE5E9;
                .class-booking-alert-btn{
                    padding: 16px;
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border-radius: 8px;
                    border: 1px solid $color_light_gray;
                    box-sizing: border-box;
                    .heading3{
                        margin-bottom: 8px;
                    }
                    .paragraph{
                        margin-bottom: 16px;
                        color: $color_gray;
                    }
                }
            }
            .how-it-works-blk{
                padding-top: 24px;
                .title{
                    margin-bottom: 16px;
                }
            }
            .common-questions-blk{
                background: rgba(255,225,228,0.2);
                padding-top: 24px;
                .common-questions-accordion{
                    background: unset;
                }
                .title{
                    margin-bottom: 16px;
                }
            }
        }
    }
}

@media (min-width: 960px){
    body{
        .subscription-detail-section{
            .subscription-detail-container{
                padding-bottom: 64px;
                .subscription-detail-header{
                    margin: 32px 150px;
                    img{
                        max-height: 300px;
                    }
                    .info-wrapper{
                        background: none;
                    }
                }
                .subscription-benefits{
                    margin: 32px 134px;
                }
                .subscription-plans{
                    margin: 32px 134px;
                }
                .class-booking-alert{
                    margin: 32px 134px;
                }
                .how-it-works-blk{

                }
                .common-questions-blk{
                    padding: 64px 150px;
                    margin: 0;
                }
            }
        }
    }
}
@media (min-width: 1279px){
    body{
        .subscription-detail-section{
            .subscription-detail-container{
                // padding: 0px 250px 0 250px;
                .subscription-detail-header{
                    margin: 64px 250px 0;
                    img{
                        max-height: 300px;
                    }
                }
                .subscription-benefits{
                    padding-right: 0;
                    padding-left: 0;
                    margin: 32px 250px;
                }
                .subscription-plans{
                    padding-right: 0;
                    padding-left: 0;
                    margin: 32px 250px;
                }
                .class-booking-alert{
                    padding-right: 0;
                    padding-left: 0;
                    margin: 32px 250px;
                }
                .how-it-works-blk{
                    margin: 0 250px;
                }
                .common-questions-blk{
                    padding: 64px 250px;
                }
            }
        }
    }
}