@import 'variable';
body{
    .edit-profile-section{
        .edit-profile-container{
            padding: 0;
            .top-blk{
                background: url("https://letzdance-fe.s3.us-east-2.amazonaws.com/profile_bg_img.svg");
                // min-height: 200px;
                background-repeat: repeat-x;
                padding: 16px 16px 0;
                margin-bottom: 24px;
                .user-avatar{
                    width: 128px;
                    height: 128px;
                    margin-right: 16px;
                    background: $color_primary;
                    color: $color_white;
                    font-size: 60px;
                    text-transform: capitalize;
                    border: 1px solid $color_primary;
                }
                .inputFileGroup{
                    position: relative;
                    .secondaryBtn{
                        width: 160px;
                        display: inline-block;
                        position: relative;
                        input{
                            display: inline-block;
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 99;
                            position: absolute;
                            cursor: pointer;
                            left: 0;
                            top: 0;
                            &::-webkit-file-upload-button {
                              cursor: pointer;
                            }
                        }
                    }
                }
            }
            .inputGroup{
                position: relative;
                .verifyLabel{
                    position: absolute;
                    background: $color_success;
                    padding: 2px 12px;
                    color: $color_white;
                    box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
                    border-radius: 4px;
                    top: 10px;
                    right: 0;
                    &.error{
                        background: $color_error;
                    }
                }
            }
            .personal-details{
                padding: 0 16px;
                border-bottom:  1px solid #FFE5E9;
                .title{
                    margin-bottom: 16px;
                }
            }
            .link-social-accounts{
                padding: 16px 16px 0;
                .heading{
                    .title{
                        margin-bottom: 4px;
                    }
                    p{
                        color: $color_gray;
                    }
                }
                .social-btn{
                    margin-top: 16px;
                    .primaryBtn{
                        &.google{
                            background: #4285F4;
                        }
                        &.facebook{
                            background: #4267B2;
                        }
                    }
                }
                .social-account-wrapper{
                    position: relative;
                    padding: 16px 0;
                    border-bottom:  1px solid #FFE5E9;
                    .icon{
                        position: absolute;
                    }
                    .close-icon{
                        position: absolute;
                        right: 0;
                        cursor: pointer;
                        top: 28px;
                    }
                    .info-blk{
                        padding-left: 48px;
                        padding-right: 20px;
                        p{
                            color: $color_info_text;
                        }
                    }
                    &.facebook{
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .edit-profile-section{
            .edit-profile-container{
                padding: 0px 250px 60px;
            }
        }
    }
}