@import 'variable';
html{
    font-size: 100%;
}

body{
    @include fontStyle(
        $fontFamily:$font_RobotoCondensed,
        $size:$font14,
        $weight:$regularFont,
        $color: $color_black,
        $lineHeight: $lineHeight16
    );

    *{
        margin: 0;
        padding: 0;
    }
    a{
        text-decoration: none;
        &:hover,&:active,&:focus,&:visited{
          text-decoration: none;
          cursor: pointer;
          outline: 0;
        }
    }
    img{
        max-width: 100%;
    }
    .flexCentered{
        display: flex;
        display: -webkit-flex;
        flex-flow: wrap;
        -webkit-flex-flow: wrap;
        align-items: center;
        &:before,&:after{
          width: 0;
        }
    }
    .disabled{
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }
    .flexNotCentered{
        display: flex;
        display: -webkit-flex;
        flex-flow: wrap;
        -webkit-flex-flow: wrap;
        &:before,&:after{
          width: 0;
        }
    }
    .backgroundProp{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .hidden{
        display: none !important;
    }
    .heading1{
        @include fontStyle(
          $fontFamily:$font_RobotoCondensed,
          $size:$font34,
          $weight:$boldFont,
          $color: $color_primary,
          $lineHeight: $lineHeight36
        );
    }
    .heading2{
        @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font26,
            $weight:$boldFont,
            $color: $color_primary,
            $lineHeight: $lineHeight30
        );
    }
    .heading3{
        @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font18,
            $weight:$boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight20
        );
    }
    .subHeading{
        @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font14,
            $weight:$boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight16
        );
    }
    .paragraph{
        @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font14,
            $weight:$regularFont,
            $color: $color_black,
            $lineHeight: $lineHeight16
        );
    }
    .secondaryText{
        @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font12,
            $weight:$boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight16
        );
    }
    .infoText{
      @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font10,
            $weight:$boldFont,
            $color: $color_black,
            $lineHeight: 12px
        );
    }
    .primaryBtn{
        box-sizing: border-box;
        width: 100%;
        @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font14,
            $weight:$boldFont,
            $color: $color_white,
            $lineHeight: $lineHeight16
        );
        text-transform: uppercase;
        background: $color_blue;
        @include transitionForAll($time: 0.4s,$transProp: ease-out);
        padding: 11px 16px;
        outline: none;
        text-align: center;
        display: inline-block;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid transparent;
        &.round{
            border-radius: 40px;
            font-size: $font12;
            padding: 7px 15px;
        }
        // &:hover,&:focus,&:active{
        //     background: $color_blue;
        //     @include transitionForAll($time: 0.3s,$transProp: ease-in);
        //     outline: none;
        // }
        &.disabled{
            pointer-events: none;
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
    .secondaryBtn{
        box-sizing: border-box;
        width: 100%;
        @include fontStyle(
          $fontFamily:$font_RobotoCondensed,
          $size:$font14,
          $weight:$boldFont,
          $color: $color_blue,
          $lineHeight: $lineHeight16
        );
        background: $color_white;
        border-radius: 4px;
        text-align: center;
        border: 1px solid $color_blue;
        cursor: pointer;
        padding: 11px 16px;
        outline: none;
        display: inline-block;
        &.disabled{
            pointer-events: none;
            opacity: 0.4;
            cursor: not-allowed;
        }
        &.round{
            border-radius: 40px;
            padding: 7px 15px;
            font-size: $font12;
        }
    }
    .listUnstyled{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .listInline{
        list-style: none;
        li, .listInlineItem{
          display: inline-block;
          width: auto;
          .MuiTouchRipple-root{
            display: none;
          }
        }
    }
    .textCenter{
        text-align: center;
    }
    .inputGroup{
        outline: none;
        margin-bottom: 16px;
        label{
            @include fontStyle(
              $fontFamily:$font_RobotoCondensed,
              $size:$font12,
              $weight:$boldFont,
              $color: $color_black,
              $lineHeight: $lineHeight16
            );
            transform: none;
            position: relative;
            display: block;
            margin-bottom: 4px;
            &.error{
                color: $color_error;
            }
        }
        input, select{
            height: 44px;
        }
        .MuiFormControl-root{
            margin: 0;
            width: 100%;
        }
        .Mui-error{
            input{
                border: 1px solid $color_error;
            }
        }
        .react-tel-input{
            input{
                padding-left: 50px;
                &:disabled{
                  opacity: 0.5;
                  background: $color_light_gray;
                }
            }
            .flag-dropdown{
                .country-list{
                    .search{
                        padding: 10px 5px;
                        .search-box{
                            margin: 0;
                            border-radius: 4px;
                        }
                    }
                }
                &.invalid-number{
                  border: 1px solid $color_error;
                }
            }
        }
        input, textarea, select{
            width: 100%;
            @include fontStyle(
              $fontFamily: $font_RobotoCondensed,
              $size:$font16,
              $weight:$boldFont,
              $color: $color_black,
              $lineHeight: 20px
            );
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid $color_light_gray;
            padding: 10px 16px;
            box-sizing: border-box;
            &.error{
                border: 1px solid $color_error;
            }
            // &:active, &:focus{
            //    border-color:$color_00D2AD;
            //    box-shadow: 0px 0px 10px rgba(0, 210, 173, 0.36)
            // }
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              @include fontStyle(
                $fontFamily:$font_RobotoCondensed,
                $size:$font16,
                $weight:$boldFont,
                $color: $color_light_gray,
                $lineHeight: $lineHeight20
              );
              opacity: 1;
              text-transform: none;
            }
            &::-moz-placeholder { /* Firefox 19+ */
              @include fontStyle(
                $fontFamily:$font_RobotoCondensed,
                $size:$font16,
                $weight:$boldFont,
                $color: $color_light_gray,
                $lineHeight: $lineHeight20
              );
               text-transform: none;
            }
            &:-ms-input-placeholder { /* IE 10+ */
              @include fontStyle(
                $fontFamily:$font_RobotoCondensed,
                $size:$font16,
                $weight:$boldFont,
                $color: $color_light_gray,
                $lineHeight: $lineHeight20
              );
               text-transform: none;
            }
            &:-moz-placeholder { /* Firefox 18- */
              @include fontStyle(
                $fontFamily:$font_RobotoCondensed,
                $size:$font16,
                $weight:$boldFont,
                $color: $color_light_gray,
                $lineHeight: $lineHeight20
              );
               text-transform: none;
            }
            .MuiTypography-body1{
              background: none;
              padding-left: 0!important;
              font-size: $font14;
            }
            &.Mui-disabled{
              border: none;
              background: #ddd;
              opacity: 0.5;
              pointer-events: none;
            }
            &:active, &:focus{
              outline: none;
            }
        }
        .MuiSelect-select{
            padding-left: 10px;
            background: $color_white;
            border-radius: 4px;
            padding: 13px 24px 12px 10px;
            border: 1px solid #E0E0E0;
            @include fontStyle(
              $fontFamily: $font_RobotoCondensed,
              $size:$font16,
              $weight:$boldFont,
              $color: $color_black,
              $lineHeight: 20px
            );
        }
          label + .MuiInput-formControl{
            margin-top: 8px;
          }
          .MuiOutlinedInput-notchedOutline{
            display: none;
          }
          .MuiInput-underline.Mui-disabled:before{
            border-bottom: none;
          }
          .MuiSelect-select.Mui-disabled{
            background: #fff;
            opacity: 0.8;
            cursor: text;
            color: rgba(0,0,0,0.6);
          }
     
          .MuiInput-underline:before{
            border-bottom: none;
          }
          .MuiInput-underline:after {
            border-bottom: none;
          }
          .MuiInputBase-multiline{
            padding: 0;
          }
          .MuiInput-underline:hover:not(.Mui-disabled):before{
            border-bottom: none;
          }
          .MuiInput-formControl{
            // margin-top: 0;
          }
          .selectFormControl{
            .MuiSelect-icon{
              right: 10px;
            }
          }
          input[type="submit"]{
            background: $color_blue;
            color: $color_white;
            height: 40px;
            font-size: $font14;
            font-weight: $boldFont;
            border-radius: 4px;
            display: block;
            padding: 11px 16px;
            border: 1px solid transparent;
            outline: none;
            text-align: center;
            cursor: pointer;
            &:disabled{
                border: none;
                pointer-events: none;
                opacity: 0.5;
                cursor: not-allowed;
            }
          }
    }
    .MuiMenu-paper{
      .MuiMenu-list{
        .MuiMenuItem-root{
          @include fontStyle(
            $fontFamily:$font_RobotoCondensed,
            $size:$font16,
            $weight:$boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight20
          );
        }
      }
    }
}