@import 'variable';
body{
    .booking-success-section{
        &.subscription{
            .booking-success-container{
                .success-wrapper{
                    border-bottom: none;
                }
            }
        }
        .booking-success-container{
            padding: 0;
            .success-wrapper{
                background-repeat: repeat-x;
                padding: 120px 16px 16px;
                border-bottom:  1px solid #FFE5E9;
                text-align: center;
                margin-top: -30px;
                img{
                    width: 32px;
                }
                .success, .heading3{
                    color: $color_success;
                    margin-bottom: 16px;
                }
                .reminder-text{
                    background: rgba(3, 148, 69, 0.03);
                    border: 1px solid $color_success;
                    box-sizing: border-box;
                    border-radius: 8px;
                    padding: 16px;
                    display: flex;
                    img{
                        width: 16px;
                        margin-right: 8px;
                    }
                    .paragraph{
                        color: $color_success;
                        text-align: left;
                    }
                }
            }
            .dance-information-card{
                padding: 16px 16px 0;
                border-bottom:  1px solid #FFE5E9;
            }
            .payment-method{
                padding: 16px;
                border-bottom:  1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .heading3{
                    .cost-old{
                        text-decoration: line-through;
                        color: $color_info_text;
                        margin-right: 8px;
                        font-size: $font14;
                    }
                }
            }
            .selectedDate{
                padding: 16px;
                border-bottom:  1px solid #FFE5E9;
                .secondaryText{
                    margin-bottom: 8px;
                }
            }
            .add-to-home-screen-blk, .invite-friends-blk{
                padding: 16px;
                border-bottom:  1px solid #FFE5E9;
            }
            .need-help{
                padding: 16px;
                .secondaryText{
                    margin-bottom: 8px;
                }
                .link{
                    color: $color_gray;
                }
            }
        }
    }
}
@media (min-width: 960px){
    body{
        .booking-success-section{
            .booking-success-container{
                padding: 0 250px 60px;
                .success-wrapper{
                    padding-bottom: 24px;
                }
                .dance-information-card{
                    padding: 24px 16px 16px;
                }
                .selectedDate, .payment-method{
                    padding: 24px 16px;
                }
                .add-to-home-screen-blk, .invite-friends-blk{
                    padding: 24px 16px;
                }
                .need-help{
                    padding: 24px 16px;
                }
            }  
        }     
    }
}