@import "variable";

body {
  .home-section {
    overflow: hidden;
    .home-container {
      padding: 0;
      .title {
        position: relative;
        margin-bottom: 16px;
        a {
          position: absolute;
          right: 16px;
          top: 7px;
          color: $color_primary;
          span,
          img {
            vertical-align: middle;
          }
          img {
            margin-top: 1px;
            width: 7px;
          }
        }
      }
      .block {
        padding-left: 16px;
        position: relative;
        padding-bottom: 44px;
        margin-bottom: 24px;
        border-bottom: 1px solid #ffe5e9;
      }
      .introduction-blk {
        padding-bottom: 8px;
        margin-bottom: 16px;
        box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
        .bannerImgWrapper {
          box-sizing: border-box;
          // text-align: center;
          img {
            width: 100%;
            margin-bottom: 8px;
            max-height: 50vh !important;
          }
          .heading1 {
            // text-align: center;
            font-size: 27px;
            color: $color_black;
            padding-left: 16px;
          }
        }
        .carousel-container {
          .carousel-item {
            box-sizing: border-box;
            height: 100%;
            img {
              width: 100%;
              margin-bottom: 8px;
              min-height: 50vh !important;
            }
            .heading1 {
              text-align: left;
              font-size: 27px;
              color: $color_black;
              padding-left: 16px;
            }
          }
          .react-multiple-carousel__arrow {
            top: 39%;
            background: $color_white;
            border: none;
            min-width: 35px;
            min-height: 35px;
            &::before {
              font-size: 16px;
              color: $color_primary;
            }
          }
        }
        .react-multi-carousel-list {
          .react-multi-carousel-track {
            li {
              margin: 0;
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
        .dance-alert-wrapper {
          margin: 16px 16px 8px;
        }
      }
      .upcoming-classes {
        padding-left: 0px;
        .title {
          margin-left: 16px;
        }
        .react-multi-carousel-list {
          .react-multi-carousel-track {
            li {
              margin-bottom: 1px;
            }
          }
        }
        .partially-visible-carousel-container {
          .carousel-item {
            .card {
              &.MuiSkeleton-root {
                border: 1px solid $color_light_gray;
              }
              cursor: pointer;
              border-radius: 8px;
              box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
              .top-blk {
                position: relative;
                .img-loader {
                  width: 100%;
                  border-radius: 8px 8px 0 0;
                }
                .logo {
                  width: 100%;
                  border-radius: 8px 8px 0 0;
                }
                .secondaryText {
                  background: $color_primary;
                  position: absolute;
                  color: $color_white;
                  right: 8px;
                  border-radius: 4px;
                  top: 8px;
                  padding: 2px 8px;
                }
                .title-wrapper {
                  position: absolute;
                  bottom: 4px;
                  padding: 13px 16px 8px;
                  width: 100%;
                  box-sizing: border-box;
                  background: linear-gradient(
                    180deg,
                    rgba(34, 34, 34, 0) 28.31%,
                    #222222 91.44%
                  );
                  left: 0;
                  p {
                    color: $color_white;
                  }
                }
              }
              .info-blk {
                padding: 16px 16px 12px;
                margin-top: -4px;
                border: 1px solid $color_light_gray;
                border-radius: 0 0 8px 8px;
                position: relative;
                h3 {
                  color: $color_primary;
                  margin-bottom: 8px;
                  img,
                  span {
                    vertical-align: middle;
                  }
                  img {
                    margin-top: -2px;
                    width: 18px;
                  }
                  span {
                    margin-left: 5px;
                    &.rating {
                      font-size: $font12;
                    }
                  }
                }
                p {
                  &.cost {
                    position: absolute;
                    right: 16px;
                    top: 17px;
                    span {
                      text-decoration: line-through;
                      color: $color_info_text;
                      font-weight: $regularFont;
                      margin-right: 5px;
                      font-size: $font14;
                    }
                  }
                }
                .subHeading {
                  margin-bottom: 8px;
                  span {
                    color: $color_info_text;
                    font-weight: $regularFont;
                    vertical-align: middle;
                    margin-left: 2px;
                  }
                  img {
                    vertical-align: middle;
                    width: 18px;
                  }
                }
                .description {
                  margin-bottom: 16px;
                  position: relative;
                  svg {
                    width: 19px;
                    fill: #999999;
                    position: absolute;
                  }
                  span {
                    display: inline-block;
                    margin-left: 23px;
                  }
                }
                ul {
                  display: flex;
                  li {
                    margin-right: 8px;
                    margin-left: 0;
                    padding: 0;
                    p {
                      text-align: center;
                      margin-top: 5px;
                      font-size: 8px;
                      text-transform: capitalize;
                      &.alert_red {
                        color: $color_primary;
                      }
                      &.alert_orange {
                        color: $color_warning;
                      }
                    }
                  }
                }
              }
            }
            .see-full-schedule-wrapper {
              cursor: pointer;
              margin-bottom: 1px;
              background: url("https://letzdance-fe.s3.us-east-2.amazonaws.com/main_bg_image.svg");
              box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
              border-radius: 8px;
              border: 1px solid $color_light_gray;
              padding: 20px 50px;
              height: 100%;
              box-sizing: border-box;
              background-repeat: round;
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              .image-wrapper {
                text-align: center;
                margin-bottom: 26px;
                p {
                  font-size: 63px;
                  line-height: 54px;
                  margin-top: -67px;
                }
              }
              .heading3 {
                color: $color_primary;
                .arrow,
                span {
                  vertical-align: middle;
                }
                span {
                  margin-right: 18px;
                }
                .arrow {
                  transform: rotate(270deg);
                  height: 24px;
                }
              }
            }
          }
        }
        .react-multiple-carousel__arrow {
          margin-bottom: 0;
        }
        .custom-dot-list {
          bottom: 18px;
        }
      }
      .last-week-recap {
        padding-left: 0;
        .title {
          margin-left: 16px;
        }
        .react-multiple-carousel__arrow {
          top: 30%;
        }
        .partially-visible-carousel-container {
          .custom-dot-list {
            bottom: 18px;
          }
        }
        .last-week-recap-item {
          cursor: pointer;
          .img-loader {
            margin-bottom: 8px;
            .MuiSkeleton-root {
              border-radius: 8px;
              box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
              border: 1px solid $color_light_gray;
            }
          }
          .img-blk {
            position: relative;
            margin-bottom: 8px;
            .dance-logo {
              width: 100%;
              box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.04);
              border-radius: 8px;
              border: 1px solid $color_light_gray;
            }
            .play-icon {
              position: absolute;
              top: 40%;
              left: 45%;
            }
          }
          h3 {
            margin-bottom: 4px;
            text-transform: capitalize;
          }
          p {
            color: $color_gray;
          }
        }
      }
      .expert-instructors {
        padding-left: 0;
        margin-bottom: 0;
        .title {
          margin-left: 16px;
        }
        .instructor-card {
          cursor: pointer;
        }
      }
      .subscriptions-blk {
        margin-top: 24px;
        padding: 24px 0 44px;
        position: relative;
        margin-bottom: 24px;
        .title {
          margin-left: 16px;
          h3,
          p {
            color: $color_white;
          }
          h3 {
            margin-bottom: 4px;
          }
        }
        background: $color_primary;
        .react-multiple-carousel__arrow {
          top: 45%;
        }
        .custom-dot-list {
          .react-multi-carousel-dot--active {
            button {
              background: #fff;
              border-color: #fff;
            }
          }
        }
      }
      .common-questions-blk {
        background: rgba(255, 225, 228, 0.2);
        padding-top: 24px;
        margin: 0;
        .common-questions-accordion {
          background: unset;
        }
      }
      .contact-us-blk {
        padding-top: 24px;
      }
      .reviews {
        padding-left: 0;
        .title {
          margin-left: 16px;
        }
      }
    }
  }
  .instructor-video {
    .instructor-video-wrapper {
    }
  }
}
@media (min-width: 960px) {
  body {
    .home-section {
      .home-container {
        .block {
          padding: 64px 0;
          margin: 0 150px;
        }
        .introduction-blk {
          margin-bottom: 0;
          padding-bottom: 0;
          box-shadow: none;
          .bannerImgWrapper {
            margin: 0 150px;
            text-align: center;
          }
          .carousel-container {
            .react-multiple-carousel__arrow {
              border: 1px solid $color_primary;
            }
          }
          .react-multiple-carousel__arrow--right {
            right: 250px;
          }
          .react-multiple-carousel__arrow--left {
            left: 250px;
          }
          .subscription-alert-wrapper {
            margin: 48px 150px 0;
          }
        }
        .subscriptions-blk {
          padding: 64px 134px 84px;
          .subcription-card-wrapper {
            // background-size: unset;
          }
          .custom-dot-list {
            // bottom: 30px;
          }
        }
        .upcoming-classes {
          &.block {
            margin: 0 134px;
          }
        }
        .expert-instructors {
          &.block {
            padding-bottom: 84px;
            margin: 0 134px;
          }
        }
        .last-week-recap {
          &.block {
            padding-bottom: 84px;
            margin: 0 134px;
          }
        }
        .reviews {
          &.block {
            padding-bottom: 84px;
            margin: 0 134px;
          }
        }
        .common-questions-blk {
          padding: 64px 150px;
        }
        .contact-us-blk {
          padding-top: 64px;
        }
      }
    }
  }
}
@media (min-width: 1279px) {
  body {
    .home-section {
      .home-container {
        .block {
          margin: 0 200px;
        }
        .introduction-blk {
          .bannerImgWrapper {
            margin: 0 200px 0;
          }
          .subscription-alert-wrapper {
            margin: 64px 200px 0;
          }
        }
        .subscriptions-blk {
          padding: 64px 200px;
          .custom-dot-list {
            bottom: 30px;
          }
        }
        .common-questions-blk {
          padding: 64px 200px;
        }
        .how-it-works-blk {
          margin-bottom: 0;
        }
        .upcoming-classes {
          &.block {
            margin: 0 184px;
          }
        }
        .expert-instructors {
          &.block {
            margin: 0 184px;
          }
        }
        .last-week-recap {
          &.block {
            margin: 0 184px;
          }
        }
        .reviews {
          &.block {
            margin: 0 184px;
          }
        }
      }
    }
  }
}
